:host {
  display: block;
  --overflow: visible;
}

.card {
  font-family: var(--sc-font-sans);
  overflow: var(--overflow);
  display: block;
}

.card:not(.card--borderless) {
  padding: var(--sc-card-padding, var(--sc-spacing-large));
  background: var(--sc-card-background-color, var(--sc-color-white));
  border: 1px solid var(--sc-card-border-color, var(--sc-color-gray-300));
  border-radius: var(--sc-input-border-radius-medium);
  box-shadow: var(--sc-shadow-small);
  &.card--no-padding {
    padding: 0;
  }
}

.title--divider {
  display: none;
}

.card--has-title-slot {
  .card--title {
    font-weight: var(--sc-font-weight-bold);
    line-height: var(--sc-line-height-dense);
  }
  .title--divider {
    display: block;
  }
}
::slotted(*) {
  margin-bottom: var(--sc-form-row-spacing);
}
::slotted(*:first-child) {
  margin-top:0;
}
::slotted(*:last-child) {
  margin-bottom:0 !important;
}
